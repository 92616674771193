<template>
  <div id="InvoiceScreen">
    <v-container>
      <v-row>
        <v-col class="col-12 col-lg-9">
          <invoice-list></invoice-list>
        </v-col>
        <v-col class="col-12 col-lg-3">
          <v-card>
            <v-app-bar dense color="transparent">
              <v-toolbar-title>
                <h5>Masukkan Pencarian</h5>
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-menu
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="invoice.fromDate"
                      label="Dari Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.fromDate"
                    @input="menuFromDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="invoice.toDate"
                      label="Sampai Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.toDate"
                    @input="menuToDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  dense
                  outlined
                  label="Nomor Faktur"
                  v-model="invoice.invoiceNo"
                  clearable
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                block
                color="primary"
                @click="onSearchClick"
                :loading="getLoading"
                :disabled="getLoading"
                >Cari</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import InvoiceList from "@/components/Widget/supplier/InvoiceList";
import globalMixin from "@/mixins/globalMixin.js";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    InvoiceList
  },

  mixins: [globalMixin],

  data() {
    return {
      valid: true,
      invoice: {},
      menuFromDate: false,
      menuToDate: false
    };
  },

  computed: {
    ...mapGetters(["getLoading"])
  },

  mounted() {
    this.getSupplierInvoices();
  },

  methods: {
    ...mapActions("invoice", ["getSupplierInvoices"]),

    onSearchClick() {
      let params = {
        start_date: this.invoice.fromDate || "",
        end_date: this.invoice.toDate || "",
        invoice_number: this.invoice.invoiceNo || ""
      };

      this.$store.dispatch("invoice/getInvoiceBySearch", params).catch(() =>
        this.$tostini({
          message: this.$store.state.message,
          type: "error"
        })
      );
    }
  }
};
</script>
