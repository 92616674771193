<template>
  <div>
    <v-card>
      <v-app-bar dense color="transparent">
        <v-toolbar-title>
          <h5>Daftar Faktur</h5>
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="onRefreshClick">mdi-refresh</v-icon>
      </v-app-bar>

      <v-card-text class="pa-0">
        <template>
          <v-data-table
            :headers="headers"
            :items="getSupplierInvoices"
            :loading="getLoading"
            loading-text="Sedang Menyiapkan Data..."
            mobile-breakpoint="0"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td
                  class="detailLink"
                  @click="getInvoiceDetailById(props.item.invoice_header_id)"
                >
                  {{ props.item.invoice_number }}
                </td>
                <td>{{ getDate(props.item.invoice_date) }}</td>
                <td>{{ props.item.store_name }}</td>
                <td>{{ props.item.payment_method }}</td>
                <td>
                  {{
                    getDate(
                      props.item.payment_date != null
                        ? props.item.payment_date
                        : "-"
                    )
                  }}
                </td>
                <td>{{ convertMinutes(props.item.due_date) }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="1200">
      <v-card class="mx-auto" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Nomor Faktur: {{ getSupplierInvoiceDetail.invoice_number }}</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-3"
            @click="
              printInvoice(getSupplierInvoiceDetail.invoice_header_id, '0')
            "
            :loading="getLoading"
            :disabled="getLoading"
            >Cetak Copy Faktur</v-btn
          >
          <v-btn
            color="primary"
            @click="
              printInvoice(getSupplierInvoiceDetail.invoice_header_id, '1')
            "
            :loading="getLoading"
            :disabled="getLoading"
            >Cetak Faktur Asli</v-btn
          >
        </v-app-bar>

        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col class="col-3">
                <div class="text-body-1 text--primary">
                  Nomor PO
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="text-body-1 text--primary">
                  :&ensp; {{ getSupplierInvoiceDetail.order_number }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="text-body-1 text--primary">
                  Metode Pembayaran
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="text-body-1 text--primary">
                  :&ensp; {{ getSupplierInvoiceDetail.payment_method }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="text-body-1 text--primary">
                  Nama Pemesan
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="text-body-1 text--primary">
                  :&ensp; {{ getSupplierInvoiceDetail.user_name }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="text-body-1 text--primary">
                  Alamat Pemesan
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="text-body-1 text--primary">
                  :&ensp; {{ getSupplierInvoiceDetail.user_address }}
                </div>
              </v-col>
            </v-row>

            <v-card class="my-10">
              <v-app-bar dense color="transparent">
                <v-toolbar-title>
                  <h5>Daftar Produk</h5>
                </v-toolbar-title>
              </v-app-bar>

              <v-card-text class="pa-0">
                <template>
                  <v-data-table
                    :headers="headersDetail"
                    :items="getSupplierInvoiceDetail.details"
                    class="elevation-0"
                    mobile-breakpoint="0"
                  >
                    <template slot="item" slot-scope="props">
                      <tr>
                        <td>{{ props.item.product_name }}</td>
                        <td>{{ props.item.product_code }}</td>
                        <td>{{ formatAmount(props.item.quantity_order) }}</td>
                        <td>
                          {{ formatRupiah(props.item.price_order) }}
                        </td>
                        <td>
                          {{ formatRupiah(props.item.subtotal) }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col class="col-6 col-md-9">
                <div class="text-h6 text--primary text-end">
                  Jumlah Barang :
                </div>
              </v-col>
              <v-col class="col-6 col-md-3">
                <div class="text-h6 text--primary text-end">
                  {{ formatAmount(getSupplierInvoiceDetail.total_item) }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-6 col-md-9">
                <div class="text-h6 text--primary text-end">
                  Total :
                </div>
              </v-col>
              <v-col class="col-6 col-md-3">
                <div class="text-h6 text--primary text-end">
                  {{ formatRupiah(getSupplierInvoiceDetail.total_price) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";
import { mapGetters } from "vuex";

export default {
  mixins: [globalMixin],

  data() {
    return {
      dialog: false,
      headers: [
        { text: "Nomor Faktur", sortable: false },
        { text: "Tanggal Faktur", sortable: false },
        { text: "Nama Toko Bangunan", sortable: false },
        { text: "Metode Pembayaran", sortable: false },
        { text: "Tanggal Pembayaran", sortable: false },
        { text: "Jatuh Tempo Dalam", sortable: false }
      ],
      headersDetail: [
        { text: "Nama Produk", sortable: false },
        { text: "SKU", sortable: false },
        { text: "Jumlah Produk", sortable: false },
        { text: "Harga Satuan", sortable: false },
        { text: "Sub Total", sortable: false }
      ]
    };
  },

  computed: {
    ...mapGetters(["getLoading"]),
    ...mapGetters("invoice", ["getSupplierInvoices"]),
    ...mapGetters("invoice", ["getSupplierInvoiceDetail"])
  },

  methods: {
    getInvoiceDetailById(id) {
      this.$root.$loaderModal.start("Loading...");

      let params = {
        id: id
      };

      this.$store
        .dispatch("invoice/getInvoiceDetailById", params)
        .then(() => (this.dialog = true))
        .catch(() =>
          this.$tostini({
            message: ERROR_SOMETHING_WRONG,
            type: "error"
          })
        )
        .finally(() => this.$root.$loaderModal.hide());
    },

    printInvoice(id, flag) {
      let params = {
        id: id,
        flag: flag,
        invoiceNo: this.getSupplierInvoiceDetail.invoice_number
      };

      this.$store.dispatch("invoice/printInvoice", params).catch(() =>
        this.$tostini({
          message: this.$store.state.message,
          type: "error"
        })
      );
    },

    onRefreshClick() {
      this.$store.dispatch("invoice/getSupplierInvoices");
    }
  }
};
</script>
